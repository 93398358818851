import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { CenteredContainer } from "@hl/base-components/lib/CenteredContainer";
import { Mail } from "@hl/base-components/lib/assets/icons.generated";
import { Group, Stack, Text } from "@mantine/core";
import { CheckGreen } from "../../assets/icons.generated";
import { useAuth } from "../auth";
import { useGetAccountSettingsQuery } from "./queries/settings.graphql.generated";
import { useRequirementStyles } from "./styles";
const EmailRequirement = ({ passed, 
// eslint-disable-next-line unused-imports/no-unused-vars
failureReasonCode, isPreview, }) => {
    var _a;
    const { classes } = useRequirementStyles();
    const { authenticated } = useAuth();
    // Only one email requirement exists at a time in a condition list
    const { data } = useGetAccountSettingsQuery({
        skip: !authenticated,
    });
    if (!authenticated || isPreview) {
        return _jsx(UserNotSignedInView, {});
    }
    const email = (_a = data === null || data === void 0 ? void 0 : data.getAccountSettings.socials.email) === null || _a === void 0 ? void 0 : _a.address;
    return (_jsx("div", { children: _jsxs(Group, { role: "listitem", spacing: "sm", noWrap: true, children: [_jsx(CenteredContainer, { border: true, children: _jsx(Mail, { width: 24, height: 24 }) }), _jsxs(Stack, { spacing: 2, children: [_jsxs(Group, { spacing: 4, children: [passed ? _jsx(CheckGreen, {}) : null, _jsx(Text, { size: "xs", className: passed ? classes.passedGate : classes.failedGate, transform: "uppercase", children: passed && "Has a verified email address" })] }), passed ? (
                        // "Passed" state
                        _jsx(Text, { size: "xs", weight: 500, children: email })) : (_jsx(Text, { size: "xs", weight: 500, children: "Email gate is disabled." }))] })] }) }));
};
const UserNotSignedInView = () => (_jsx("div", { children: _jsxs(Group, { role: "listitem", spacing: "sm", noWrap: true, children: [_jsx(CenteredContainer, { border: true, children: _jsx(Mail, { width: 24, height: 24 }) }), _jsx(Stack, { spacing: 2, children: _jsx(Text, { size: "xs", weight: 500, children: "Email gate is disabled." }) })] }) }));
export default EmailRequirement;
