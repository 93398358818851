import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Center } from "@mantine/core";
export const CenteredContainer = ({ children, border = false, borderRadius = "round", width = 64, height = 64, }) => {
    return (_jsx(Center, { sx: (theme) => ({
            minWidth: width,
            minHeight: height,
            backgroundColor: theme.colors.gray[0],
            ...(border && { border: `0.5px solid ${theme.colors.black[0]}` }),
            borderRadius: borderRadius === "round" ? "50%" : undefined,
        }), children: children }));
};
