import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const CheckGateAccessDocument = gql `
  query CheckGateAccess($gateId: String!) {
    checkGateAccess(gateId: $gateId, fullResult: true) {
      passed
      type
      failureReasons {
        code
        reason
      }
      gateOwnerId
      conditionResults {
        type
        selector
        required
        amount
        failureReason {
          code
          reason
        }
        data {
          network
          address
          tokenId
          attributes {
            key
            value
          }
          allowlistLength
          followFID
          followName
          followFcUrl
        }
        autoBuyButton
        buyButtonUrl
        category
        passed
        userAmount
      }
    }
  }
`;
/**
 * __useCheckGateAccessQuery__
 *
 * To run a query within a React component, call `useCheckGateAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckGateAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckGateAccessQuery({
 *   variables: {
 *      gateId: // value for 'gateId'
 *   },
 * });
 */
export function useCheckGateAccessQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CheckGateAccessDocument, options);
}
export function useCheckGateAccessLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CheckGateAccessDocument, options);
}
export const GateTokenDocument = gql `
  query GateToken(
    $contractAddress: String!
    $tokenId: String
    $platform: String!
    $conditionType: String
  ) {
    gateToken(
      contractAddress: $contractAddress
      tokenId: $tokenId
      platform: $platform
      conditionType: $conditionType
    ) {
      platform
      imageUrl
      tokenId
      name
      contractType
      address
      symbol
    }
  }
`;
/**
 * __useGateTokenQuery__
 *
 * To run a query within a React component, call `useGateTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGateTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGateTokenQuery({
 *   variables: {
 *      contractAddress: // value for 'contractAddress'
 *      tokenId: // value for 'tokenId'
 *      platform: // value for 'platform'
 *      conditionType: // value for 'conditionType'
 *   },
 * });
 */
export function useGateTokenQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GateTokenDocument, options);
}
export function useGateTokenLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GateTokenDocument, options);
}
export const CollectionDetailsDocument = gql `
  query CollectionDetails($slug: String!) {
    collectionDetails(slug: $slug) {
      imageUrl
      name
      standard
      description
      id
      slug
    }
  }
`;
/**
 * __useCollectionDetailsQuery__
 *
 * To run a query within a React component, call `useCollectionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCollectionDetailsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CollectionDetailsDocument, options);
}
export function useCollectionDetailsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CollectionDetailsDocument, options);
}
export const ContractDetailsDocument = gql `
  query ContractDetails($address: String!, $network: String!) {
    contractDetails(address: $address, network: $network) {
      imageUrl
      name
      standard
      contract
      symbol
      openSeaCollectionUrl
    }
  }
`;
/**
 * __useContractDetailsQuery__
 *
 * To run a query within a React component, call `useContractDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractDetailsQuery({
 *   variables: {
 *      address: // value for 'address'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useContractDetailsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(ContractDetailsDocument, options);
}
export function useContractDetailsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(ContractDetailsDocument, options);
}
export const CollectionNftsDocument = gql `
  query CollectionNfts($slug: String!) {
    collectionNfts(slug: $slug) {
      imageUrl
      name
      standard
      balance
      contract
      id
    }
  }
`;
/**
 * __useCollectionNftsQuery__
 *
 * To run a query within a React component, call `useCollectionNftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionNftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionNftsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCollectionNftsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CollectionNftsDocument, options);
}
export function useCollectionNftsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CollectionNftsDocument, options);
}
export const ContractNftsDocument = gql `
  query ContractNfts($address: String!, $network: String!) {
    contractNfts(address: $address, network: $network) {
      imageUrl
      name
      standard
      backgroundColor
      balance
      contract
      description
      externalUrl
      id
      uri
    }
  }
`;
/**
 * __useContractNftsQuery__
 *
 * To run a query within a React component, call `useContractNftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractNftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractNftsQuery({
 *   variables: {
 *      address: // value for 'address'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useContractNftsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(ContractNftsDocument, options);
}
export function useContractNftsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(ContractNftsDocument, options);
}
export const CollectionNftDocument = gql `
  query CollectionNft($collectionNftId: String!, $slug: String!) {
    collectionNft(id: $collectionNftId, slug: $slug) {
      balance
      contract
      id
      imageUrl
      name
      standard
    }
  }
`;
/**
 * __useCollectionNftQuery__
 *
 * To run a query within a React component, call `useCollectionNftQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionNftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionNftQuery({
 *   variables: {
 *      collectionNftId: // value for 'collectionNftId'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCollectionNftQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CollectionNftDocument, options);
}
export function useCollectionNftLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CollectionNftDocument, options);
}
export const CurrencyDetailsDocument = gql `
  query CurrencyDetails($address: String!, $network: String!) {
    currencyDetails(address: $address, network: $network) {
      contract
      imageUrl
      name
      standard
      symbol
    }
  }
`;
/**
 * __useCurrencyDetailsQuery__
 *
 * To run a query within a React component, call `useCurrencyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrencyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrencyDetailsQuery({
 *   variables: {
 *      address: // value for 'address'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useCurrencyDetailsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CurrencyDetailsDocument, options);
}
export function useCurrencyDetailsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CurrencyDetailsDocument, options);
}
export const ContractNftDocument = gql `
  query ContractNft(
    $contractNftId: String!
    $address: String!
    $network: String!
  ) {
    contractNft(id: $contractNftId, address: $address, network: $network) {
      backgroundColor
      attributes {
        key
        value
      }
      balance
      contract
      description
      externalUrl
      id
      imageUrl
      name
      standard
      uri
    }
  }
`;
/**
 * __useContractNftQuery__
 *
 * To run a query within a React component, call `useContractNftQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractNftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractNftQuery({
 *   variables: {
 *      contractNftId: // value for 'contractNftId'
 *      address: // value for 'address'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useContractNftQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(ContractNftDocument, options);
}
export function useContractNftLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(ContractNftDocument, options);
}
