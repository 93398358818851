import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { Label } from "@hl/base-components/lib/Label";
import { ArrowUpRight, FarcasterIcon, Warn as WarningIcon, } from "@hl/base-components/lib/assets/icons.generated";
import { BACKGROUND_COLOR, TEXT_COLOR, } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Anchor, Box, Center, Grid, Group, Image, Paper, Skeleton, Stack, Text, } from "@mantine/core";
import { ConditionType, } from "../../apollo/graphql.generated";
import { CheckGreen } from "../../assets/icons.generated";
import AllowlistIcon from "../../assets/icons.generated/AllowlistIcon";
import { EXTERNAL_URL } from "../../config";
import { buildContractUrl } from "../../utils/blockExplorer";
import { ZERO_ADDRESS } from "../../utils/blockchain";
import { maskAddress } from "../../utils/content";
import { getNativeCurrencyIcon, getNativeCurrencyName, } from "../../utils/currency";
import { buildOpenSeaLinkUrl } from "../../utils/opensea";
import EmailRequirement from "./EmailRequirement";
import { useContractDetailsQuery, useContractNftQuery, useCollectionDetailsQuery, useCollectionNftQuery, useCurrencyDetailsQuery, } from "./queries/gate.graphql.generated";
import { useRequirementStyles } from "./styles";
const REQUIREMENT_IMAGE_SIZE = 68;
const NativeTokenRequirement = ({ platform, passed, requiredCount, address, }) => {
    var _a;
    const { classes } = useRequirementStyles();
    const isNative = address === ZERO_ADDRESS;
    const { data: contractData } = useCurrencyDetailsQuery({
        variables: { network: platform, address },
        skip: isNative,
    });
    const symbol = isNative
        ? getNativeCurrencyName(platform)
        : (_a = contractData === null || contractData === void 0 ? void 0 : contractData.currencyDetails.symbol) !== null && _a !== void 0 ? _a : contractData === null || contractData === void 0 ? void 0 : contractData.currencyDetails.name;
    return (_jsxs(Group, { role: "listitem", spacing: 12, children: [isNative ? (_jsx(GenericGateContainer, { children: getNativeCurrencyIcon(platform, 24) })) : (contractData === null || contractData === void 0 ? void 0 : contractData.currencyDetails.imageUrl) ? (_jsx(Image, { width: REQUIREMENT_IMAGE_SIZE, height: REQUIREMENT_IMAGE_SIZE, fit: "scale-down", src: contractData === null || contractData === void 0 ? void 0 : contractData.currencyDetails.imageUrl, alt: `${symbol} image`, radius: 6 })) : (_jsx(GenericGateContainer, { children: `$${symbol}` })), _jsxs(Stack, { spacing: 4, children: [_jsxs(Group, { spacing: 4, children: [passed ? _jsx(CheckGreen, {}) : null, _jsxs(Label, { className: passed ? classes.passedGate : classes.failedGate, children: ["Hold at least ", requiredCount] })] }), _jsx(Text, { fw: WEIGHT_BOLD, transform: "uppercase", children: symbol }), platform] })] }));
};
const AllowlistRequirement = ({ passed, allowlistLength, }) => {
    const { classes } = useRequirementStyles();
    return (_jsxs(Group, { role: "listitem", spacing: 12, children: [_jsx(GenericGateContainer, { children: _jsx(AllowlistIcon, { width: 24, height: 24 }) }), _jsxs(Stack, { spacing: 2, children: [_jsxs(Group, { spacing: 4, children: [passed ? _jsx(CheckGreen, {}) : null, _jsx(Label, { className: passed ? classes.passedGate : classes.failedGate, children: "Is on the specified allowlist:" })] }), _jsx(Group, { children: _jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: `${allowlistLength} wallet address${allowlistLength === 1 ? "" : "es"}` }) })] })] }));
};
const FarcasterRequirement = ({ passed, followName, followFcUrl, }) => {
    const { classes } = useRequirementStyles();
    return (_jsxs(Group, { role: "listitem", spacing: 12, noWrap: true, children: [_jsx(GenericGateContainer, { children: _jsx(FarcasterIcon, { width: 24, height: 24 }) }), _jsxs(Stack, { spacing: 2, children: [_jsxs(Group, { spacing: 4, children: [passed ? _jsx(CheckGreen, {}) : null, _jsx(Label, { className: passed ? classes.passedGate : classes.failedGate, children: "Follows a Farcaster account" })] }), _jsxs(Text, { size: "sm", children: ["Collectors must follow", " ", _jsx(Anchor, { href: followFcUrl, target: "_blank", rel: "noreferrer", size: "sm", fw: 500, sx: { whiteSpace: "nowrap" }, children: followName })] })] })] }));
};
export const FcVerifiedWalletRequired = () => (_jsx(Box, { px: 16, py: 8, sx: (theme) => ({
        borderRadius: 6,
        border: `1px solid ${theme.colors.warningStatus[0]}`,
        backgroundColor: theme.colors.warningWashStatus[0],
    }), children: _jsxs(Group, { noWrap: true, children: [_jsx(WarningIcon, { width: 34, height: 34 }), _jsxs(Text, { size: "sm", children: ["Collectors must", " ", _jsx(Anchor, { href: "https://warpcast.com/~/settings/verified-addresses", target: "_blank", rel: "noreferrer", size: "sm", fw: 500, children: "verify their wallet" }), " ", "to meet Farcaster requirements"] })] }) }));
export const SkeletonConditionRequirement = () => {
    return (_jsx(Paper, { children: _jsxs(Grid, { children: [_jsx(Grid.Col, { span: 4, children: _jsx(Center, { children: _jsx(Skeleton, { height: 60, circle: true, mb: "xl" }) }) }), _jsxs(Grid.Col, { span: 8, children: [_jsx(Skeleton, { height: 8, mt: 6, radius: "xl" }), _jsx(Skeleton, { height: 8, mt: 6, radius: "xl" }), _jsx(Skeleton, { height: 8, mt: 6, radius: "xl" }), _jsx(Skeleton, { height: 8, mt: 6, width: "70%", radius: "xl" })] })] }) }));
};
export const ConditionRequirement = ({ address, contractType, platform, passed, tokenId = "", requiredCount, attributes, allowlistLength, conditionType, creatorAddress, creatorEns, failureReasonCode, creatorId, refetchOnRequirementChange, isPreview, followFcUrl, followName, }) => {
    var _a, _b;
    const { classes } = useRequirementStyles();
    const { data: contractData } = useContractDetailsQuery({
        variables: { network: platform, address },
        skip: !(address && platform),
    });
    const { data: contractNftData } = useContractNftQuery({
        variables: { network: platform, address, contractNftId: tokenId },
        skip: !(address && platform && tokenId),
    });
    const isCollection = contractType === "COLLECTION" ||
        (contractType === "ATTRIBUTE" && !platform);
    const { data: collectionData } = useCollectionDetailsQuery({
        variables: { slug: address },
        skip: !isCollection,
    });
    const { data: collectionNftData } = useCollectionNftQuery({
        variables: { slug: address, collectionNftId: tokenId },
        skip: !(address && tokenId),
    });
    if (contractType === "CURRENCY") {
        return (_jsx(NativeTokenRequirement, { platform: platform, address: address, requiredCount: requiredCount, passed: passed }));
    }
    if (conditionType === ConditionType.ALLOWLIST) {
        return (_jsx(AllowlistRequirement, { passed: passed, allowlistLength: allowlistLength !== null && allowlistLength !== void 0 ? allowlistLength : 0 }));
    }
    if (conditionType === ConditionType.FARCASTER) {
        return (_jsx(FarcasterRequirement, { passed: passed, followFcUrl: followFcUrl, followName: followName }));
    }
    if (conditionType === ConditionType.MARKETING_ACCESS) {
        return (_jsx(EmailRequirement, { creatorAddress: creatorAddress, creatorEns: creatorEns, passed: passed, failureReasonCode: failureReasonCode, creatorId: creatorId, refetchOnRequirementChange: refetchOnRequirementChange, isPreview: isPreview }));
    }
    const openSeaLink = isCollection
        ? `${EXTERNAL_URL.OPENSEA_BASE}/collection/${address}`
        : (_b = (_a = contractData === null || contractData === void 0 ? void 0 : contractData.contractDetails) === null || _a === void 0 ? void 0 : _a.openSeaCollectionUrl) !== null && _b !== void 0 ? _b : buildOpenSeaLinkUrl(address, platform, tokenId);
    const contractLink = buildContractUrl(platform.toUpperCase(), address);
    const imageUrl = tokenId
        ? isCollection
            ? collectionNftData === null || collectionNftData === void 0 ? void 0 : collectionNftData.collectionNft.imageUrl
            : contractNftData === null || contractNftData === void 0 ? void 0 : contractNftData.contractNft.imageUrl
        : isCollection
            ? collectionData === null || collectionData === void 0 ? void 0 : collectionData.collectionDetails.imageUrl
            : contractData === null || contractData === void 0 ? void 0 : contractData.contractDetails.imageUrl;
    const name = isCollection
        ? collectionData === null || collectionData === void 0 ? void 0 : collectionData.collectionDetails.name
        : contractData === null || contractData === void 0 ? void 0 : contractData.contractDetails.name;
    return (_jsxs(Group, { role: "listitem", spacing: 12, noWrap: true, children: [_jsx(Box, { w: REQUIREMENT_IMAGE_SIZE, children: contractType !== "native" && imageUrl && (_jsx(Image, { width: REQUIREMENT_IMAGE_SIZE, height: REQUIREMENT_IMAGE_SIZE, src: imageUrl, alt: "Image", radius: 6 })) }), _jsxs(Stack, { spacing: 2, children: [_jsxs(Group, { spacing: 4, children: [passed ? _jsx(CheckGreen, {}) : null, tokenId ? (_jsxs(Label, { className: passed ? classes.passedGate : classes.failedGate, children: ["Hold token ID", " ", isCollection ? maskAddress(tokenId, 2, 2) : tokenId, " from"] })) : (_jsxs(Label, { className: passed ? classes.passedGate : classes.failedGate, children: ["Hold any", " ", requiredCount !== "1" ? `${requiredCount} tokens ` : "token ", "from"] }))] }), _jsxs(Group, { spacing: 0, children: [_jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: name }), attributes.length && (_jsxs(_Fragment, { children: [_jsx(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: "\u00A0with\u00A0" }), _jsxs(Text, { size: "sm", fw: 500, children: [attributes[0].key, ": ", attributes[0].value] })] }))] }), _jsxs(Group, { spacing: 4, children: [isCollection ? (_jsx(Text, { size: "sm", children: maskAddress(address, 4, 4) })) : (_jsx(Anchor, { size: "sm", href: contractLink, target: "_blank", rel: "noreferrer", children: maskAddress(address, 4, 4) })), _jsx(Text, { size: "sm", component: "span", children: "\u00B7" }), _jsxs(Group, { spacing: 0, children: [_jsx(Anchor, { size: "sm", href: openSeaLink, target: "_blank", rel: "noreferrer", children: "OpenSea" }), _jsx(ArrowUpRight, {})] })] })] })] }));
};
const GenericGateContainer = ({ children }) => (_jsx(Center, { bg: BACKGROUND_COLOR.SECONDARY, w: REQUIREMENT_IMAGE_SIZE, h: REQUIREMENT_IMAGE_SIZE, sx: (theme) => ({
        border: `1px solid ${theme.colors.divider[0]}`,
        borderRadius: 6,
        flexShrink: 0,
    }), children: children }));
