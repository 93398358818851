import { createStyles } from "@mantine/core";
export const useRequirementStyles = createStyles((theme) => {
    return {
        failedGate: {
            color: theme.colors.secondaryText[0],
        },
        lightText: {
            color: theme.colors.secondaryText[0],
        },
        passedGate: {
            color: theme.colors.affirmativeStatus[0],
            fontWeight: 500,
        },
    };
});
