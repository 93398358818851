import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Stack } from "@mantine/core";
import { ConditionType, FailureReasonCode, } from "../../apollo/graphql.generated";
import { ConditionRequirement, FcVerifiedWalletRequired, } from "./ConditionRequirement";
export const GateConditionsList = ({ conditionResults, showFirstN, creatorAddress, creatorEns, creatorId, refetchOnRequirementChange, isPreview, }) => {
    let results = conditionResults !== null && conditionResults !== void 0 ? conditionResults : [];
    if (results && showFirstN && showFirstN > 0) {
        results = [...results].splice(0, showFirstN);
    }
    return (_jsxs(Stack, { role: "list", children: [results.map((requirement, i) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
                return (_jsx(ConditionRequirement, { address: (_b = (_a = requirement.data) === null || _a === void 0 ? void 0 : _a.address) !== null && _b !== void 0 ? _b : "", tokenId: (_d = (_c = requirement.data) === null || _c === void 0 ? void 0 : _c.tokenId) !== null && _d !== void 0 ? _d : "", passed: requirement.passed, contractType: (_e = requirement.type) !== null && _e !== void 0 ? _e : "", requiredCount: ((_f = requirement.amount) !== null && _f !== void 0 ? _f : 0.0).toString(), platform: (_h = (_g = requirement.data) === null || _g === void 0 ? void 0 : _g.network) !== null && _h !== void 0 ? _h : "", attributes: (_k = (_j = requirement.data) === null || _j === void 0 ? void 0 : _j.attributes) !== null && _k !== void 0 ? _k : [], allowlistLength: (_m = (_l = requirement.data) === null || _l === void 0 ? void 0 : _l.allowlistLength) !== null && _m !== void 0 ? _m : (_p = (_o = requirement.data) === null || _o === void 0 ? void 0 : _o.allowlist) === null || _p === void 0 ? void 0 : _p.length, conditionType: (_q = requirement.type) !== null && _q !== void 0 ? _q : undefined, creatorAddress: creatorAddress, creatorEns: creatorEns, failureReasonCode: (_r = requirement.failureReason) === null || _r === void 0 ? void 0 : _r.code, creatorId: creatorId, refetchOnRequirementChange: refetchOnRequirementChange, isPreview: isPreview, followName: (_t = (_s = requirement.data) === null || _s === void 0 ? void 0 : _s.followName) !== null && _t !== void 0 ? _t : "", followFcUrl: (_v = (_u = requirement.data) === null || _u === void 0 ? void 0 : _u.followFcUrl) !== null && _v !== void 0 ? _v : "" }, i));
            }), results.some((req) => {
                var _a;
                return (!req.passed &&
                    req.type === ConditionType.FARCASTER &&
                    req.failureReason === null) ||
                    ((_a = req.failureReason) === null || _a === void 0 ? void 0 : _a.code) === FailureReasonCode.FC_WALLET_NOT_CONNECTED;
            }) && _jsx(FcVerifiedWalletRequired, {})] }));
};
