import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const AccountSettingsDataFragmentDoc = gql `
  fragment AccountSettingsData on AccountSettings {
    id
    socials {
      email {
        address
        verifiedOn
      }
    }
    marketingPreferences {
      allowedCreators {
        id
        walletAddress
        ensName
        grantedAccess
        email
      }
    }
  }
`;
export const GetAccountSettingsDocument = gql `
  query GetAccountSettings {
    getAccountSettings {
      ...AccountSettingsData
    }
  }
  ${AccountSettingsDataFragmentDoc}
`;
/**
 * __useGetAccountSettingsQuery__
 *
 * To run a query within a React component, call `useGetAccountSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountSettingsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetAccountSettingsDocument, options);
}
export function useGetAccountSettingsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetAccountSettingsDocument, options);
}
export const ClearPendingEmailDocument = gql `
  mutation ClearPendingEmail {
    clearPendingEmail {
      ...AccountSettingsData
    }
  }
  ${AccountSettingsDataFragmentDoc}
`;
/**
 * __useClearPendingEmailMutation__
 *
 * To run a mutation, you first call `useClearPendingEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearPendingEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearPendingEmailMutation, { data, loading, error }] = useClearPendingEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearPendingEmailMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(ClearPendingEmailDocument, options);
}
export const LinkEmailDocument = gql `
  mutation LinkEmail(
    $emailAddress: String!
    $redirectUrl: String!
    $creatorId: String
  ) {
    linkEmail(
      emailAddress: $emailAddress
      redirectUrl: $redirectUrl
      creatorId: $creatorId
    ) {
      ...AccountSettingsData
    }
  }
  ${AccountSettingsDataFragmentDoc}
`;
/**
 * __useLinkEmailMutation__
 *
 * To run a mutation, you first call `useLinkEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkEmailMutation, { data, loading, error }] = useLinkEmailMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      redirectUrl: // value for 'redirectUrl'
 *      creatorId: // value for 'creatorId'
 *   },
 * });
 */
export function useLinkEmailMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(LinkEmailDocument, options);
}
export const GrantCreatorEmailMarketingAccessDocument = gql `
  mutation GrantCreatorEmailMarketingAccess($data: GrantMarketingAccessInput!) {
    grantCreatorMarketingAccess(data: $data) {
      ...AccountSettingsData
    }
  }
  ${AccountSettingsDataFragmentDoc}
`;
/**
 * __useGrantCreatorEmailMarketingAccessMutation__
 *
 * To run a mutation, you first call `useGrantCreatorEmailMarketingAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantCreatorEmailMarketingAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantCreatorEmailMarketingAccessMutation, { data, loading, error }] = useGrantCreatorEmailMarketingAccessMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGrantCreatorEmailMarketingAccessMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(GrantCreatorEmailMarketingAccessDocument, options);
}
